import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { CircularProgress, Grid } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { delay } from 'bluebird'
import _ from 'lodash'
import { DateTime } from 'luxon'
import PullToRefresh from 'pulltorefreshjs'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { bayArea } from '../areas/bayArea'
import { hoodRiver } from '../areas/hoodRiver'
import { southTexas } from '../areas/southTexas'
import { ventana } from '../areas/ventana'
import {
  loadAiForecast,
  loadDailyForecast,
  loadExtendedForecast,
  loadSpots,
  resetCache,
} from '../reducer'
import DailyForecast from './DailyForecast'
import ExtendedForecast from './ExtendedForecast'
import Footer from './Footer'
import { LiveWindSummary } from './LiveWindSummary'
import SpotSection, { ForecastWrapper } from './SpotSection'
import SpotsMap from './SpotsMap'
import TopBar from './TopBar'
import { AiForecastOverview } from './AiForecast'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export type MapSettings = {
  coordinates: {
    lat: number
    lng: number
  }
  zoom: number
  googleMapsZoom: number
}

export type Area = {
  key: string
  name: string
  mapSettings: MapSettings
  forecastId?: string
  spots: {
    title: string
    spotId: number
    forecastSpotId?: number
    liveWindImageUrl?: string
    webcamUrl?: string

    // Clockwise
    offshore?: { start: number; end: number }
  }[]
}

const useStyles = makeStyles((theme) => ({
  sections: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  forecast: {
    padding: theme.spacing(2),
  },
  map: {
    marginTop: theme.spacing(4),
  },
}))

export default function App(): JSX.Element {
  const classes = useStyles()
  const dispatch = useDispatch()
  const spotsWithData = useSelector((state) => state.spotsWithData)
  const dailyForecast = useSelector((state) => state.dailyForecast)
  const extendedForecast = useSelector((state) => state.extendedForecast)
  const aiForecast = useSelector((state) => state.aiForecast)
  const token = useSelector((state) => state.token)
  const location = useLocation()
  const [area, setArea] = useState<Area | undefined>()

  useEffect(() => {
    const search = new URLSearchParams(location.search)
    const areaString = search.get('area') ?? 'bayArea'
    let newArea: Area
    switch (areaString) {
      case 'hoodRiver':
        newArea = hoodRiver
        break
      case 'ventana':
        newArea = ventana
        break
      case 'bayArea':
        newArea = bayArea
        break
      case 'southTexas':
        newArea = southTexas
        break
      default:
        newArea = bayArea
        break
    }
    dispatch(resetCache())
    setArea(newArea)
  }, [location, dispatch])

  const fullReload = useCallback(() => {
    if (!token || !area) {
      return
    }
    dispatch(loadSpots({ area, token }))
    if (area.forecastId) {
      dispatch(loadDailyForecast({ forecastId: area.forecastId, token }))
      dispatch(loadExtendedForecast({ forecastId: area.forecastId, token }))
      const favoriteSpotIds = area.spots.map((spot) => spot.spotId)
      dispatch(loadAiForecast({ forecastId: area.forecastId, favoriteSpotIds, token }))
    }
  }, [area, dispatch, token])

  // Initial data load
  useEffect(() => {
    fullReload()
  }, [fullReload])

  // Timers
  useEffect(() => {
    const interval = setInterval(() => {
      if (!token || !area) {
        return
      }
      dispatch(loadSpots({ area, token }))
    }, 60000)

    const onFocus = () => {
      if (!token || !area) {
        return
      }
      dispatch(loadSpots({ area, token }))
    }

    window.addEventListener('focus', onFocus)

    return () => {
      clearInterval(interval)
      window.removeEventListener('focus', onFocus)
    }
  }, [area, dispatch, token])

  useEffect(() => {
    PullToRefresh.init({
      mainElement: 'body',
      onRefresh: async (): Promise<void> => {
        fullReload()
        await delay(1000)
      },
    })

    return () => {
      PullToRefresh.destroyAll()
    }
  }, [fullReload])

  if (!area || !spotsWithData) {
    return (
      <Grid container justifyContent="center" style={{ marginTop: 40 }}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  const spotSections = _.map(spotsWithData, (spot) => {
    const spotForecast = _.find(
      dailyForecast?.spots ?? [],
      (s) => s.id === (spot.forecastSpotId ?? spot.spotId)
    )

    let forecastWrapper: ForecastWrapper | undefined
    if (dailyForecast && spotForecast) {
      forecastWrapper = {
        forecast: spotForecast,
        forecastedDay: dailyForecast.forecastedDay,
        forecastCreatedAt: DateTime.fromISO(dailyForecast.createdAt),
        timeZone: dailyForecast.timeZone,
      }
    }

    return (
      <SpotSection
        xs={12}
        md={6}
        lg={4}
        key={spot.spotId}
        spotWithData={spot}
        forecastWrapper={forecastWrapper}
      />
    )
  })

  return (
    <>
      <TopBar area={area} />
      <LiveWindSummary spotsWithData={spotsWithData} />
      <AiForecastOverview forecast={aiForecast} area={area} />
      {/* Wrap in div to work around https://material-ui.com/components/grid/#negative-margin */}
      <div className={classes.sections}>
        <Grid container spacing={4}>
          {spotSections}
        </Grid>
      </div>
      <div className={classes.map}>
        <SpotsMap height={600} mapSettings={area.mapSettings} />
      </div>
      {/* Wrap in div to work around https://material-ui.com/components/grid/#negative-margin */}
      <div className={classes.forecast}>
        <Grid container spacing={4}>
          {dailyForecast && <DailyForecast forecast={dailyForecast} />}
          {extendedForecast && <ExtendedForecast forecast={extendedForecast} />}
        </Grid>
      </div>
      <Footer />
    </>
  )
}
